import React from 'react';
import Button from './global/Button';
import Heading from './global/Heading';
import Picture from './global/Picture';
import Typography from './global/Typography';
import StyledBlogCard from './BlogCard.style';

const BlogCard = ({
  image,
  title: { content: title },
  date: { content: date },
  text: { content: text },
  link,
  ...p
}) => {
  return (
    <StyledBlogCard {...p}>
      <Picture
        images={{
          1: image.src,
        }}
        alt={image.alt}
        width={273}
        height={164}
      />
      <Heading css={{ marginTop: 10 }} variant="heading3" as="h3">
        {title}
      </Heading>
      <Typography
        css={{ marginTop: 8 }}
        variant="controlStrong"
        color="bahamaBlue"
        role="doc-subtitle"
      >
        {date}
      </Typography>
      <Typography
        css={{ marginTop: 20 }}
        variant="control"
        color="blueCharcoal"
        lineHeight="160%"
      >
        {text}
      </Typography>
      <Button css={{ marginTop: 15 }} arrow variant="text" href={link.href}>
        {link.content}
      </Button>
    </StyledBlogCard>
  );
};
export default BlogCard;
