import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Container from '../../components/global/Container';
import Heading from '../../components/global/Heading';
import Carousel from '../../components/Carousel';
import BlogCard from '../../components/BlogCard';
import { useBreakpoint } from '../../app/providers/breakpointProvider';
import SwitchableCarousel from '../../components/SwitchableCarousel';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const RecentBlogsOverviewModule = ({ title, items, ...p }) => {
  const carousel = useBreakpoint().isDown('desktopSmall');
  return (
    <Container {...p} flex col gap="20px">
      {!!title && <Heading title={title} />}
      <SwitchableCarousel itemWidth={273} carousel={carousel}>
        {items?.map((p, ix) => (
          <BlogCard key={ix} {...p} />
        ))}
      </SwitchableCarousel>
    </Container>
  );
};

export default RecentBlogsOverviewModule;

registerModule({ RecentBlogsOverview: Self });
