import styled from '@emotion/styled';

export default styled.div`
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.quartz};
  padding: 20px 15px 15px;
  picture {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: -21px -16px 0;
    width: calc(100% + 32px);
    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;
