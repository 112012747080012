import React from 'react';
import Carousel from './Carousel';
import { GridStyle } from './SwitchableCarousel.style';

const SwitchableCarousel = ({ carousel, gridStyle, ...p }) => {
  return (
    <>{carousel ? <Carousel {...p} /> : <GridStyle className={gridStyle}>{p.children}</GridStyle>}</>
  );
};
export default SwitchableCarousel;
