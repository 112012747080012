import styled from '@emotion/styled';

export const GridStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  ${({ theme }) => theme.media.desktop} {
    gap: 30px;
  }

  &.three-col {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
`;
